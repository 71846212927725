<template>
  <div class="container">
    <div class="tabs-nav">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="成绩汇总" name="0">
          <Summary ref="summary" />
        </el-tab-pane>
        <el-tab-pane label="体质检测成绩" name="1">
          <Constitution ref="constitution" />
        </el-tab-pane>
        <el-tab-pane label="心理测评" name="2">
          <Mind ref="mind" />
        </el-tab-pane>
        <el-tab-pane label="健康知识测评" name="3">
          <Healthy ref="healthy" />
        </el-tab-pane>
        <el-tab-pane label="体育出勤率" name="4">
          <Attendance ref="attendance" />
        </el-tab-pane>
        <el-tab-pane label="体育比赛" name="5">
          <Competition ref="competition" />
        </el-tab-pane>
        <el-tab-pane label="卫生习惯" name="6">
          <Hygienic ref="hygienic" />
        </el-tab-pane>
		<el-tab-pane label="中考体育" name="7">
		  <MidPhyExam ref="midPhyExam" />
		</el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Summary from './component/resultsSummary'
import Constitution from './component/constitution'
import Mind from './component/mind'
import Healthy from './component/healthy'
import Attendance from './component/attendance'
import Competition from './component/competition'
import Hygienic from './component/hygienic'
import MidPhyExam from './component/midPhyExam'

export default {
  data() {
    return {
      activeName: "0",
    };
  },
  components: {
    Summary,
    Constitution,
    Mind,
    Healthy,
    Attendance,
    Competition,
    Hygienic,
	MidPhyExam
  },
  created() {
    this.activeName = this.$route.params.activeName
    this.handleClick()
  },
  methods: {
    handleClick(tab, event) {
      // console.log(tab, event);
      this.$nextTick(()=>{
        switch(this.activeName) {
          case '0': this.$refs.summary.getFirst();break;
          case '1': this.$refs.constitution.getFirst();break;
          case '2': this.$refs.mind.getFirst();break;
          case '3': this.$refs.healthy.getFirst();break;
          case '4': this.$refs.attendance.handleClick();break;
          case '5': this.$refs.competition.getFirst();break;
          case '6': this.$refs.hygienic.getFirst();break;
			// case '7': this.$refs.midPhyExam.tabClick();break;
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  .tabs-nav {
    width: 100%;
    padding: 15px 16px 30px;
    background: #fff;
    border-radius: 4px 4px 0px 0px;
    box-sizing: border-box;
  }
}
</style>
<style scoped>
.el-tabs--top >>> .el-tabs__item.is-top:nth-child(2){
  padding-left: 20px;
}
</style>