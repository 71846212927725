<template>
  <div class="web-content">
    <Search
      :testStatus="true"
      :termlist="termlist"
      :classlist="classlist"
      ref="search"
      @handleQuery="handleQuery"
    />
    <el-row type="flex" justify="space-between">
      <el-col :span="6">
        <h3>心理测评</h3>
      </el-col>
      <el-col :span="18" class="btn" style="text-align: right">
        <a :href="pdfUrl" target="_blank">
          <el-button type="text">国家学生体质健康标准</el-button>
        </a>
        <el-button
          type="primary"
          style="display: inline-block; margin-left: 10px"
          plain
          ><download-excel
            :data="tableData"
            :fields="fields"
            name="心理测评.xls"
          >
            导出
          </download-excel></el-button
        >
      </el-col>
    </el-row>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      :header-cell-style="tableHeaderColor"
      :cell-style="cellStyleFun"
    >
      <el-table-column type="index" label="序号" width="50"> </el-table-column>
      <el-table-column prop="userName" label="学生姓名"> </el-table-column>
      <el-table-column prop="loginName" label="学籍号" width="180">
      </el-table-column>
      <el-table-column prop="className" label="班级" width="120">
      </el-table-column>
      <el-table-column prop="evaResult" label="测评结果" width="120">
      </el-table-column>
      <el-table-column prop="indexScore" label="综素得分" width="120">
      </el-table-column>
      <el-table-column prop="evaStatus" label="测评状态" width="120">
      </el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <el-button @click="handleDetail(scope.row)" type="text" size="small"
            >详情</el-button
          >
          <el-button @click="handleUrge(scope.row)" type="text" size="small"
            >催一下</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />
  </div>
</template>

<script>
import Search from "@/components/Search";
import { getThreeTest, hurryUp } from "@/api/teacher/quality/health";
export default {
  data() {
    return {
      pdfUrl: `${process.env.VUE_APP_API}/shida-user-biz/static/国家学生体质健康标准.pdf`,
      tableData: [],
      total: 0,
      queryParams: {
        typeId: "400",
        evaStatus: "",
        pageSize: 100,
      },
      typeId: "320",
      termlist: [],
      classlist: [],
      fields: {
        学生姓名: "userName",
        学籍号: "loginName",
        班级: "className",
        测评结果: "evaResult",
        综素得分: "indexScore",
        测评状态: "evaStatus",
      },
    };
  },
  components: { Search },
  created() {
    // this.queryParams.term = this.$store.getters.queryForm.termName
    // this.queryParams.deptId = this.$store.getters.queryForm.deptId
    // this.getList()
  },
  methods: {
    getFirst() {
      this.$store.dispatch("GetTermList").then((res) => {
        this.termlist = res.data;
      });
      this.$store.dispatch("GetClassList", this.typeId).then((res) => {
        this.classlist = res.data;
        this.queryParams.term = this.$store.getters.queryForm.termName;
        this.queryParams.deptId = this.$store.getters.queryForm.deptId;
        this.$refs.search.init(this.queryParams.term, this.queryParams.deptId);
        this.getList();
      });
    },
    getList(status) {
      getThreeTest(this.queryParams).then((res) => {
        // console.log(res);
        let arr = res.data.list;
        if (status != undefined && status.length > 0) {
          this.tableData = arr.filter((item) => {
            return item.evaStatus == status;
          });
        } else {
          this.tableData = arr;
        }
        this.total = res.data.size;
      });
    },
    handleQuery(data) {
      this.queryParams.term = data.term;
      this.queryParams.deptId = data.deptId;
      this.queryParams.userNameOrLoginName = data.userName;
      this.queryParams.evaStatus = data.evaStatus;
      this.getList(data.evaStatus);
    },
    handleDetail(row) {
      if (row.evaStatus == "已测评") {
        const params = {
          term: this.queryParams.term,
          evaResult: row.evaResult,
          id: row.id,
          questionnaireId: row.questionnaireId,
          typeId: row.typeId,
          userId: row.userId,
		  evaTypeId: '400'
        };
        this.$router.push({
          name: "questionnaire",
          params: params,
        });
        sessionStorage["params"] = JSON.stringify(params);
      } else {
        this.$message.warning("该学生还未做测评，你可以催一下");
      }
    },
    handleUrge(row) {
      if (row.evaStatus == "未测评") {
        hurryUp({
          content: "你的心理测评还未完成，老师催你了",
          msgType: "400",
          userId: row.userId,
        }).then((res) => {
          this.$message.success("已向该学生发送催促消息！");
        });
      } else {
        this.$message.warning("该学生已做完测评，不用再催啦！");
      }
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color: #F6F7FA;color: #616266;font-weight: blod;text-align:center";
      }
    },
    cellStyleFun() {
      return "text-align:center";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/el-tabs.scss";
</style>