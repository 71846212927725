<template>
  <div class="web-content">
    <Search
      :termlist="termlist"
      :classlist="classlist"
      ref="search"
      @handleQuery="handleQuery"
    />
    <el-row type="flex" justify="space-between">
      <el-col :span="6">
        <h3>体育比赛</h3>
      </el-col>
      <el-col :span="18" class="btn" style="text-align: right">
        <a :href="pdfUrl" target="_blank">
          <el-button type="text">国家学生体质健康标准</el-button>
        </a>
        <el-button
          type="primary"
          style="display: inline-block; margin-left: 10px"
          plain
        >
          <download-excel
            :data="tableData"
            :fields="fields"
            name="体育比赛.xls"
          >
            导出
          </download-excel>
        </el-button>
      </el-col>
    </el-row>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      :header-cell-style="tableHeaderColor"
      :cell-style="cellStyleFun"
    >
      <el-table-column type="index" label="序号" width="50"> </el-table-column>
      <el-table-column prop="userName" label="学生姓名"> </el-table-column>
      <el-table-column prop="studentCode" label="学籍号" width="180">
      </el-table-column>
      <el-table-column prop="className" label="班级" width="120">
      </el-table-column>
      <el-table-column prop="matchName" label="比赛名称"> </el-table-column>
      <el-table-column prop="indexRuleLevel" label="级别"> </el-table-column>
      <el-table-column prop="matchTime" label="比赛时间"> </el-table-column>
      <el-table-column label="操作" width="250">
        <template slot-scope="scope">
          <el-button @click="handleDetail(scope.row)" type="text" size="small"
            >详情</el-button
          >
		  <el-button :disabled="scope.row.auditStatus == 1" @click="granted(scope.row)" type="text" size="small"
		    >通过</el-button
		  >
		  <el-button :disabled="scope.row.auditStatus == 2" @click="deny(scope.row)" type="text" size="small"
		    >不通过</el-button
		  >
          <el-button @click="handleRemove(scope.row)" type="text" size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />

    <!-- 查看详情 -->
    <el-dialog title="体育比赛" :visible.sync="open" width="600px" center>
      <el-form
        ref="form"
        :model="form"
        style="
          border-top: 1px solid #e1e3e9;
          border-bottom: 1px solid #e1e3e9;
          padding-top: 5px;
        "
      >
        <el-form-item style="border-bottom: 1px dashed #e1e3e9">
          <span class="sname">{{ form.userName }}</span>
          <span class="snumb">学籍号：{{ form.studentCode }}</span>
        </el-form-item>
        <el-form-item label="比赛名称：">
          <span class="scontent">{{ form.matchName }}</span>
        </el-form-item>
        <el-form-item label="比赛级别：">
          <span class="scontent">{{ form.indexRuleLevel }}</span>
        </el-form-item>
        <el-form-item label="比赛时间：">
          <span class="scontent">{{ form.matchTime }}</span>
        </el-form-item>
		<el-form-item label="获奖等级：" prop="resultLevel">
			<span class="scontent">{{ form.resultLevel }}</span>
		</el-form-item>
        <el-form-item
          label="举办单位："
          style="border-bottom: 1px dashed #e1e3e9"
        >
          <span class="scontent">{{ form.awardedUnit }}</span>
        </el-form-item>
        <el-form-item label="所在学期：">
          <span class="scontent">{{ form.termName }}</span>
        </el-form-item>
        <el-form-item label="佐证材料：" v-if="form._showImgList">
        	<el-image
        	  v-for="(item, index) in form._imgFileList"
        		style="width: 100px; height: 100px;"
        		:preview-src-list="form._imgFileList"		
        	  :key="index"
        	  :src="item"
        	></el-image>
        </el-form-item>
        <el-form-item label="佐证材料：" v-if="form._showOtherFileList">
        	<a
        	v-for="(item, idx) in form._otherFileList"
        	:key="item"
        	target="_blank"
        	:download="'附件' + idx"
        	:href="item">附件{{ idx + 1 }} </a>
        </el-form-item>
        <el-form-item label="备注说明：">
          <span class="scontent">{{ form.bz }}</span>
        </el-form-item>
		<el-form-item v-if="form.auditStatus == 2" label="驳回者：">
			<span class="scontent">{{ form.updateBy }}</span>
		</el-form-item>
		<el-form-item v-if="form.auditStatus == 2" label="驳回原因：">
			<span class="scontent">{{ form.causeDes }}</span>
		</el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="open = false">关 闭</el-button>
      </span>
    </el-dialog>
	<!-- 审核不通过 -->
	<el-dialog title="审核不通过" :visible.sync="dialog.auditDeny" width="600px" center>
		<el-form :model="denyform" ref="denyform">
			<el-form-item prop="causeDes" label="不通过原因：">
				<el-input placeholder="请输入不通过原因" v-model="denyform.causeDes" type="textarea"></el-input>
			</el-form-item>
		</el-form>
		<div slot="footer" class="dialog-footer">
		  <el-button @click="closeDialog('auditDeny')">关 闭</el-button>
		  <el-button type='primary' @click="confirmDeny">确 定</el-button>
		</div>
	</el-dialog>
  </div>
</template>

<script>
import Search from "@/components/Search";
import {
  getCompetionList,
  getCompetionDetail,
  removeCompetion,
  phyAudit
} from "@/api/teacher/quality/health";
export default {
  data() {
    return {
      imgSrc: this.imageSrc(),
      pdfUrl: `${process.env.VUE_APP_API}/shida-user-biz/static/国家学生体质健康标准.pdf`,
      tableData: [],
      open: false,
      form: {},
      total: 0,
      queryParams: {
        pageSize: 100,
      },
      typeId: "320",
      termlist: [],
      classlist: [],
      fields: {
        学生姓名: "userName",
        学籍号: "studentCode",
        班级: "className",
        比赛名称: "matchName",
        级别: "indexRuleLevel",
        比赛时间: "matchTime",
      },
	  dialog: {
	  	auditDeny: false  
	  },
	  denyform: {
		id: '',
		auditStatus: 2,
		causeDes: ''
	  },
    }
  },
  components: { Search },
  created() {
    // this.queryParams.termName = this.$store.getters.queryForm.termName
    // this.queryParams.classId = this.$store.getters.queryForm.deptId
    // this.getList()
  },
  methods: {
    getFirst() {
      this.$store.dispatch("GetTermList").then((res) => {
        this.termlist = res.data;
      });
      this.$store.dispatch("GetClassList", this.typeId).then((res) => {
        this.classlist = res.data;
        this.queryParams.termName = this.$store.getters.queryForm.termName;
        this.queryParams.classId = this.$store.getters.queryForm.deptId;
        this.$refs.search.init(
          this.queryParams.termName,
          this.queryParams.classId
        );
        this.getList();
      });
    },
    getList() {
      getCompetionList(this.queryParams).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.size;
      });
    },
    handleQuery(data) {
      this.queryParams.classId = data.deptId;
      this.queryParams.termName = data.term;
      this.queryParams.searchStr = data.userName;
      this.getList();
    },
	// 处理附件
	processAth(key) {
		let temp = key.split(",")
		let imgFileList = []
		let otherFileList = []
		// 材料按文件后缀名分流
		temp.forEach(v => {
			if (this.fileNotImg(v)) {
				otherFileList.push(this.imgSrc + v)
			} else {
				imgFileList.push(this.imgSrc + v)
			}
		})
		this.form._imgFileList = imgFileList
		this.form._otherFileList = otherFileList
		this.form._showImgList = imgFileList.length > 0
		this.form._showOtherFileList = otherFileList.length > 0
	},
    handleDetail(row) {
      getCompetionDetail({ matchId: row.id }).then((res) => {
        this.form = res.data;
        if(this.form.proofFile) {
          this.processAth(this.form.proofFile)
        }
        this.open = true;
      });
    },
	// 打开弹窗
	openDialog(nm) {
		this.dialog[nm] = true
	},
	// 关闭弹窗
	closeDialog(nm) {
		this.dialog[nm] = false
	},
	// 通过
	granted(item) {
		phyAudit({
			id: item.id,
			auditStatus: 1,
			causeDes: ''
		}).then(res => {
			this.$message.success('体育比赛审核通过')
			this.getList()
		})
	},
	// 不通过
	deny(item) {
		this.denyform.id = item.id
		this.denyform.causeDes = ''
		this.openDialog('auditDeny')
	},
	// 确认不通过
	confirmDeny() {
		phyAudit(this.denyform).then(res => {
			this.$message.success('体育比赛审核不通过')
			this.closeDialog('auditDeny')
			this.getList()
		})
	},
    handleRemove(row) {
      this.$confirm(
        "删除该条记录后，学生对应的综素得分将被扣除，确定要删除吗？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          removeCompetion({ matchId: row.id }).then((res) => {
            this.$message.success("删除成功");
            this.getList();
          });
        })
        .catch(() => {
          return false;
        });
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color: #F6F7FA;color: #616266;font-weight: blod;text-align:center";
      }
    },
    cellStyleFun() {
      return "text-align:center";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/el-tabs.scss";
@import "@/assets/scss/studentResult.scss";
</style>
<style scoped>
.el-form-item {
  margin-bottom: 12px;
}
.el-form-item >>> .el-form-item__label {
  font-weight: bold;
  color: #303133;
}
.el-image {
  width: 300px;
}
</style>