<template>
  <div class="web-content">
    <Search
      :termlist="termlist"
      :classlist="classlist"
      ref="search"
      @handleQuery="handleQuery"
    />
    <el-row type="flex" justify="space-between">
      <el-col :span="6">
        <h3>体育出勤率</h3>
      </el-col>
      <el-col :span="18" class="btn" style="text-align: right">
        <a :href="pdfUrl" target="_blank">
          <el-button type="text">国家学生体质健康标准</el-button>
        </a>
        <!-- <el-button type="primary" plain @click="handleImport">导入</el-button> -->
        <el-button type="primary" style="display: inline-block; margin-left: 10px" plain
          ><download-excel
            :data="tableData"
            :fields="fields"
            name="体育出勤率.xls"
            
          >
            导出
          </download-excel></el-button
        >
      </el-col>
    </el-row>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      :header-cell-style="tableHeaderColor"
      :cell-style="cellStyleFun"
    >
      <el-table-column type="index" label="序号" width="50"> </el-table-column>
      <el-table-column prop="userName" label="学生姓名"> </el-table-column>
      <el-table-column prop="userNum" label="学籍号" width="180">
      </el-table-column>
      <el-table-column prop="className" label="班级" width="120">
      </el-table-column>
      <el-table-column prop="sportShouldNum" label="体育课应出勤" width="120">
      </el-table-column>
      <el-table-column prop="sportRealNum" label="体育课实出勤" width="120">
      </el-table-column>
      <el-table-column prop="sportRatio" label="体育课出勤率" width="120">
      </el-table-column>
      <el-table-column prop="middleShouldNum" label="课间操应出勤" width="120">
      </el-table-column>
      <el-table-column prop="middleRealNum" label="课间操实出勤" width="120">
      </el-table-column>
      <el-table-column prop="middleRatio" label="课间操出勤率" width="120">
      </el-table-column>
      <el-table-column prop="sportAttendScore" label="综素得分">
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="120">
        <template slot-scope="scope">
          <el-button @click="handleDetail(scope.row)" type="text" size="small"
            >查看详情</el-button
          >
          <!-- <el-button @click="handleEdit(scope.row)" type="text" size="small"
            >数据维护</el-button
          > -->
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />

    <!-- <el-dialog :title="title" :visible.sync="open" width="600px" center>
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="120px"
        label-position="left"
      >
        <el-form-item
          label-width="0"
          style="
            border-top: 1px solid #e1e3e9;
            border-bottom: 1px dashed #e1e3e9;
          "
        >
          <span class="sname">{{ form.userName }}</span>
          <span class="snumb">学籍号：{{ form.userNum }}</span>
        </el-form-item>
        <el-form-item label="学生状态：" prop="userStatus">
          <el-select
            style="width: 100%"
            v-model="form.userStatus"
            placeholder="请选择学生状态"
          >
            <el-option
              v-for="(item, index) in statuslist"
              :key="index"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="体育课应出勤：" prop="sportShouldNum">
          <el-input
            v-model="form.sportShouldNum"
            @change="sportShouldNum"
          ></el-input>
        </el-form-item>
        <el-form-item label="体育课实出勤：" prop="sportRealNum">
          <el-input
            v-model="form.sportRealNum"
            @change="sportRealNum"
          ></el-input>
        </el-form-item>
        <el-form-item label="课间操应出勤：" prop="middleShouldNum">
          <el-input
            v-model="form.middleShouldNum"
            @change="middleShouldNum"
          ></el-input>
        </el-form-item>
        <el-form-item label="课间操实出勤：" prop="middleRealNum">
          <el-input
            v-model="form.middleRealNum"
            @change="middleRealNum"
          ></el-input>
        </el-form-item>
        <el-form-item label="体育课出勤率：" prop="sportRatio">
          <span class="scontent">{{ form.sportRatio }}</span>
        </el-form-item>
        <el-form-item label="课间操出勤率：" prop="middleRatio">
          <span class="scontent">{{ form.middleRatio }}</span>
        </el-form-item>
        <el-form-item label="综素得分：" prop="sportAttendScore">
          <span class="scontent">{{ form.sportAttendScore }}</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="open = false">取 消</el-button>
        <el-button type="primary" @click="hanldeSubmit">确 定</el-button>
      </span>
    </el-dialog> -->

    <!-- 导入 -->
    <!-- <el-dialog
      :title="title"
      :visible.sync="openImport"
      width="600px"
      center
      :show-close="false"
    >
      <el-row :gutter="20" type="flex" justify="center">
        <el-col :span="1.5">
          <a :href="attendUrl" target="_blank" download="体育出勤率模板.xls">
            <el-button type="primary" size="small">下载模板</el-button>
          </a>
        </el-col>
        <el-col :span="1.5">
          <el-upload
            class="upload-demo"
            action="nothing"
            :on-change="handleUpChange"
            :on-remove="handleRemove"
            :on-exceed="handleExceed"
            :file-list="form.fileList"
            :limit="1"
            :auto-upload="false"
            :show-file-list="true"
          >
            <el-button size="small" type="primary">选择文件</el-button>
          </el-upload>
        </el-col>
      </el-row>
      <p style="font-size: 12px; color: red; text-align: center">
        注：请先下载体育出勤率模板，并在原文件内填写，再导入该文件
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submitImport">确 定</el-button>
      </span>
    </el-dialog> -->

    <el-dialog
      title="体育出勤率"
      center
      width="850px"
      :visible.sync="openDetail"
      :close-on-click-modal="false"
      :destroy-on-close="true"
    >
      <el-form :model="form" ref="form">
        <el-form-item label="班级：">
          <span class="scontent">{{ form.className }}</span>
        </el-form-item>
        <el-form-item label="学期：">
          <span class="scontent">{{ queryParams.termName }}</span>
        </el-form-item>
        <el-form-item label="学生姓名：">
          <span class="scontent">{{ form.userName }}</span>
        </el-form-item>
        <el-form-item label="学籍号：">
          <span class="scontent">{{ form.userNum }}</span>
        </el-form-item>
        <el-form-item label="学生考勤：">
          <el-row><el-col></el-col></el-row>
          <el-row :gutter="10" type="flex" justify="end">
            <el-col :span="7"></el-col>
            <el-col :span="17" style="text-align: right">
              <el-select v-model="detailParams.attendanceType" @change="getListDetail" placeholder="出勤类型" size="mini" style="width: 150px;margin-right:10px">
                <el-option label="体育课出勤" value="1"></el-option>
                <el-option label="课间操出勤" value="2"></el-option>
              </el-select>
              <el-select v-model="detailParams.attendanceStatus" @change="getListDetail" placeholder="出勤状态" size="mini" style="width: 150px;margin-right:10px">
                <el-option label="缺勤" value="0"></el-option>
                <el-option label="出勤" value="1"></el-option>
              </el-select>
              <el-select v-show="false" v-model="form.attendanceStatus" placeholder="缺勤原因" size="mini" style="width: 150px;margin-right:10px" clearable>
                <el-option label="缺勤" value="0"></el-option>
                <el-option label="出勤" value="1"></el-option>
              </el-select>
              <!-- <el-button
                type="primary"
                size="mini"
                style="margin-left: 10px"
                @click="getListDetail"
                >搜索</el-button
              > -->
            </el-col>
          </el-row>
          <template v-if="detailParams.attendanceType == 1">
            <el-table
              style="width: 100%"
              border
              :header-cell-style="tableHeaderColor"
              :cell-style="cellStyleFun"
              :data="form.attendanceStudents"
            >
              <el-table-column
                type="index"
                label="序号"
                width="50"
              ></el-table-column>
              <el-table-column
                property="shangkeTime"
                label="上课时间"
                width="180"
              ></el-table-column>
              <el-table-column
                property="shangkeTeacher"
                label="上课老师"
                min-width="150"
              ></el-table-column>
              <el-table-column
                property="attendanceStatus"
                label="出勤状态"
                width="130"
              >
                <template slot-scope="scope">
                  {{ scope.row.attendanceStatus == '0' ? '缺勤' : '出勤' }}
                </template>
              </el-table-column>
              <el-table-column
                property="unattendanceCause"
                label="缺勤原因"
                width="150"
              ></el-table-column>
            </el-table>
          </template>

          <template v-if="detailParams.attendanceType == 2">
            <el-table
              style="width: 100%"
              border
              :header-cell-style="tableHeaderColor"
              :cell-style="cellStyleFun"
              :data="form.attendanceStudents"
            >
              <el-table-column
                type="index"
                label="序号"
                width="50"
              ></el-table-column>
              <el-table-column
                property="shangkeTime"
                label="课间操时间"
                width="180"
              ></el-table-column>
              <el-table-column
                property="shangkeTeacher"
                label="课间操时段"
                min-width="150"
              ></el-table-column>
              <el-table-column
                property="attendanceStatus"
                label="出勤状态"
                width="130"
              >
                <template slot-scope="scope">
                  {{ scope.row.attendanceStatus == '0' ? '缺勤' : '出勤' }}
                </template>
              </el-table-column>
              <el-table-column
                property="unattendanceCause"
                label="缺勤原因"
                width="150"
              ></el-table-column>
            </el-table>
          </template>

          <pagination
            v-show="totalDetail > 0"
            :total="totalDetail"
            :page.sync="detailParams.pageNum"
            :limit.sync="detailParams.pageSize"
            @pagination="getListDetail"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="openDetail = false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Search from "@/components/Search";
import {
  getStudentStatus,
  importExcel,
  getAttendance,
  updateAttendance,
  getAttendanceDetail,
} from "@/api/teacher/quality/health";
export default {
  data() {
    return {
      tableData: [],
      open: false,
      openImport: false,
      openDetail: false,
      title: "",
      attendUrl: `${process.env.VUE_APP_API}/shida-user-biz/static/sportAttend.xls`,
      pdfUrl: `${process.env.VUE_APP_API}/shida-user-biz/static/国家学生体质健康标准.pdf`,
      total: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 100,
      },
      totalDetail: 0,
      detailParams: {
        pageNum: 1,
        pageSize: 15,
        attendanceType: '1',
        attendanceStatus: '1',
      },
      typeId: "320",
      termlist: [],
      classlist: [],
      statuslist: [],
      form: {
        fileList: [],
		attendanceStudents: []
      },
      rules: {
        userStatus: [
          { required: true, message: "请选择学生状态", trigger: "change" },
        ],
        sportShouldNum: [
          { required: true, message: "请输入体育课应出勤", trigger: "blur" },
        ],
        sportRealNum: [
          { required: true, message: "请输入体育课实出勤", trigger: "blur" },
        ],
        middleShouldNum: [
          { required: true, message: "请输入课间操应出勤", trigger: "blur" },
        ],
        middleRealNum: [
          { required: true, message: "请输入课间操实出勤", trigger: "blur" },
        ],
      },
      fields: {
        学生姓名: "userName",
        学籍号: "userNum",
        班级: "className",
        体育课应出勤: "sportShouldNum",
        体育课实出勤: "sportRealNum",
        体育课出勤率: "sportRatio",
        课间操应出勤: "middleShouldNum",
        课间操实出勤: "middleRealNum",
        课间操出勤率: "middleRatio",
        综素得分: "sportAttendScore",
      },
    };
  },
  components: { Search },
  methods: {
    getFirst() {
      this.$store.dispatch("GetTermList").then((res) => {
        this.termlist = res.data;
      });
      this.$store.dispatch("GetClassList", this.typeId).then((res) => {
        this.classlist = res.data;
        this.queryParams.termName = this.$store.getters.queryForm.termName;
        this.queryParams.classId = this.$store.getters.queryForm.deptId;
        this.$refs.search.init(
          this.queryParams.termName,
          this.queryParams.classId
        );
        this.getList();
      });
    },
    getList() {
      getAttendance(this.queryParams).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.size;
      });
    },
    handleQuery(data) {
      this.queryParams.termName = data.term;
      this.queryParams.classId = data.deptId;
      this.queryParams.searchStr = data.userName;
      this.getList();
    },
    handleDetail(row) {
      this.form = row
      this.detailParams.userId = row.userId
	  this.detailParams.termName = this.queryParams.termName
	  this.detailParams.attendanceStatus = '1'
	  this.detailParams.attendanceType = '1'
      this.getListDetail('openDetail')
    },
    getListDetail(modal) {
		this.form.attendanceStudents = []
      getAttendanceDetail(this.detailParams).then(res => {
		let d = res.data
        this.totalDetail = d.total
        if(this.detailParams.attendanceType == '2') {
          d.list.forEach(item => {
            item.shangkeTeacher = parseInt(item.shangkeTime.slice(11,13)) <= 12 ? '上午' : '下午'
          })
        }
		this.form.attendanceStudents = d.list
		this.$forceUpdate()
		if (modal) {
			this[modal] = true
		}
      })
    },
    // handleEdit(row) {
    //   getAttendanceDetail({
    //     termName: this.queryParams.termName,
    //     userId: row.userId,
    //   }).then((res) => {
    //     this.open = true;
    //     this.title = "体育出勤率";
    //     this.getStatus();
    //     this.form = res.data;
    //     if (this.form.userStatus == "正常") {
    //       this.form.userStatus = "0";
    //     } else if (this.form.userStatus == "残疾") {
    //       this.form.userStatus = "1";
    //     } else if (this.form.userStatus == "受伤") {
    //       this.form.userStatus = "2";
    //     }
    //   });
    // },
    // hanldeSubmit() {
    //   this.$refs["form"].validate((valid) => {
    //     if (valid) {
    //       updateAttendance(this.form).then((res) => {
    //         this.open = false;
    //         this.getList();
    //         this.resetForm("form");
    //       });
    //     } else {
    //       return false;
    //     }
    //   });
    // },
    // handleImport() {
    //   this.openImport = true;
    //   this.title = "导入体育出勤率";
    // },
    // submitImport() {
    //   var formData = new FormData();
    //   formData.append("file", this.form.file);
    //   importExcel(formData).then((res) => {
    //     // console.log(res);
    //     this.$message.success("数据导入成功");
    //     this.openImport = false;
    //     this.form.fileList = [];
    //     this.getList();
    //   });
    // },
    cancel() {
      this.form.fileList = [];
      this.openImport = false;
    },
    handleUpChange(file, fileList) {
      this.form.file = file.raw;
      this.$message.success("导入成功");
    },
    handleRemove() {},
    handleExceed() {
      this.$message.error("每次只能上传一个文件！");
    },
    sportShouldNum(val) {
      if (val < this.form.sportRealNum) {
        this.$message.warning("应出勤不能小于实出勤");
        this.form.sportShouldNum = this.form.sportRealNum;
      }
    },
    sportRealNum(val) {
      if (val > this.form.sportShouldNum) {
        this.$message.warning("实出勤不能大于应出勤");
        this.form.sportRealNum = this.form.sportShouldNum;
      }
    },
    middleShouldNum(val) {
      if (val < this.form.middleRealNum) {
        this.$message.warning("应出勤不能小于实出勤");
        this.form.middleShouldNum = this.form.middleRealNum;
      }
    },
    middleRealNum(val) {
      if (val > this.form.middleShouldNum) {
        this.$message.warning("实出勤不能大于应出勤");
        this.form.middleRealNum = this.form.middleShouldNum;
      }
    },
    getStatus() {
      getStudentStatus({ type: "userStatus" }).then((res) => {
        this.statuslist = res.data;
      });
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color: #F6F7FA;color: #616266;font-weight: blod;text-align:center";
      }
    },
    cellStyleFun() {
      return "text-align:center";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/el-tabs.scss";
@import "@/assets/scss/studentResult.scss";
</style>
<style scoped>
.el-form-item {
  margin-bottom: 16px;
}
.el-form-item >>> .el-form-item__label {
  font-weight: bold;
  color: #303133;
}
</style>