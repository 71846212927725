<template>
  <div class="web-content">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="体育出勤率" name="1">
        <Attendance ref="attendance"></Attendance>
      </el-tab-pane>
      <el-tab-pane label="体育课考勤" name="2">
        <Sports ref="sports"></Sports>
      </el-tab-pane>
      <el-tab-pane label="课间操考勤" name="3">
        <During ref="during"></During>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Attendance from './component/attendance.vue'
import Sports from './component/sports.vue'
import During from './component/during.vue'
export default {
  data() {
    return {
      activeName: '1',
    };
  },
  components: { Attendance, Sports, During },
  methods: {
    handleClick() {
      this.$nextTick(() => {
        switch(this.activeName) {
          case '1': this.$refs.attendance.getFirst(); break;
          case '2': this.$refs.sports.getFirst(); break;
          case '3': this.$refs.during.getFirst(); break;
        }
      })
    },
    
  },
};
</script>

<style lang="scss" scoped>
</style>