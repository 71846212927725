<template>
  <div class="web-content">
    <Search
      :constitution="true"
      :termlist="termlist"
      :classlist="classlist"
      ref="search"
      @handleQuery="handleQuery"
    />
    <el-row type="flex" justify="space-between">
      <el-col :span="6">
        <h3>体质检测成绩</h3>
      </el-col>
      <el-col :span="18" class="btn" style="text-align: right">
        <a :href="pdfUrl" target="_blank">
          <el-button type="text">国家学生体质健康标准</el-button>
        </a>
        <!-- <el-button type="primary" plain @click="handleImport">导入</el-button> -->
        <el-button
          type="primary"
          style="display: inline-block; margin-left: 10px"
          plain
          ><download-excel
            :data="tableData"
            :fields="fields"
            name="体质检测成绩.xls"
          >
            导出
          </download-excel></el-button
        >
      </el-col>
    </el-row>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      :header-cell-style="tableHeaderColor"
      :cell-style="cellStyleFun"
    >
      <el-table-column type="index" label="序号" width="50"> </el-table-column>
      <el-table-column prop="userName" label="学生姓名"> </el-table-column>
      <el-table-column prop="loginName" label="学籍号" width="180">
      </el-table-column>
      <el-table-column prop="deptName" label="班级" width="120">
      </el-table-column>
      <el-table-column prop="studentStatus" label="学生状态" width="120">
      </el-table-column>
      <el-table-column prop="totalGradesScore" label="体质检测成绩" width="120">
      </el-table-column>
      <el-table-column prop="totalIndexScore" label="综素得分" width="120">
      </el-table-column>
      <el-table-column label="操作" width="120">
        <!-- <template slot-scope="scope">
          <el-button @click="handleEdit(scope.row)" type="text" size="small"
            >数据维护</el-button
          >
        </template> -->
		<template slot-scope="scope">
		  <el-button @click="handleCheck(scope.row)" type="text" size="small"
		    >查看</el-button
		  >
		</template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />

    <!-- 导入 -->
    <el-dialog
      title="导入成绩"
      :visible.sync="openImport"
      width="600px"
      center
      :show-close="false"
    >
      <el-row :gutter="20" type="flex" justify="center">
        <el-col :span="1.5">
          <a :href="usualUrl" target="_blank" download="体质检测成绩模板.xls">
            <el-button type="primary" size="small">下载模板</el-button>
          </a>
        </el-col>
        <el-col :span="1.5">
          <el-upload
            class="upload-demo"
            action="nothing"
            :on-change="handleUpChange"
            :on-remove="handleRemove"
            :on-exceed="handleExceed"
            :file-list="form.fileList"
            :limit="1"
            :auto-upload="false"
            :show-file-list="true"
          >
            <el-button size="small" type="primary">选择文件</el-button>
          </el-upload>
        </el-col>
      </el-row>
      <p style="font-size: 12px; color: red; text-align: center">
        注：请先下载体质检测成绩模板，并在原文件内填写，再导入该文件
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submitImport">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 数据维护 -->
    <el-dialog title="体质检测成绩" :visible.sync="open" width="700px" center>
      <el-form
        ref="form"
        :model="form"
        style="
          border-top: 1px solid #e1e3e9;
          border-bottom: 1px solid #e1e3e9;
          padding-top: 5px;
        "
      >
        <el-form-item style="border-bottom: 1px dashed #e1e3e9">
          <span class="sname">{{ form.userName }}</span>
          <span class="snumb">学籍号：{{ form.studentCode }}</span>
        </el-form-item>
        <el-form-item label="学生状态：">
          <el-select
            v-model="form.studentStatus"
            placeholder="请选择"
            clearable
            @change="handleChange"
          >
            <el-option
              v-for="item in stuStatus"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="综素成绩：">
          <span class="scontent">{{ form.totalIndexScore }}</span>
        </el-form-item>
        <el-form-item label="检测成绩：" v-if="form.studentStatus == '0'">
          <el-table
            :data="form.conlist"
            style="width: 100%"
            border
            :header-cell-style="tableHeaderColor"
            :cell-style="cellStyleFun"
          >
            <el-table-column type="index" label="序号" width="50">
            </el-table-column>
            <el-table-column prop="project" label="检测项"> </el-table-column>
            <el-table-column prop="type" label="测评结果" width="120">
              <template slot-scope="scope">
                <el-input
                  size="small"
                  v-model="scope.row.type"
                  :disabled="scope.$index == 2"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="typeGradesScore"
              label="体质检测成绩"
              width="120"
            ></el-table-column>
            <el-table-column prop="typeIndexScore" label="综素得分" width="120">
            </el-table-column>
            <el-table-column prop="typeLevel" label="等级" width="120">
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="检测成绩：" v-if="form.studentStatus == '1'">
          <span class="scontent"
            >根据教育部发布的学生综合素质评价规则，因残疾无法参与体质检测的学生，默认获得70%综素成绩</span
          >
        </el-form-item>
        <el-form-item label="检测成绩：" v-if="form.studentStatus == '2'">
          <span class="scontent"
            >根据教育部发布的学生综合素质评价规则，因受伤无法参与体质检测的学生，默认获得60%综素成绩</span
          >
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>
	
	<!-- 数据查看 -->
	<el-dialog title="体质检测成绩" :visible.sync="openDetail" width="700px" center>
	  <el-form
	    ref="form"
	    :model="form"
	    style="
	      border-top: 1px solid #e1e3e9;
	      border-bottom: 1px solid #e1e3e9;
	      padding-top: 5px;
	    "
	  >
	    <el-form-item style="border-bottom: 1px dashed #e1e3e9">
	      <span class="sname">{{ form.userName }}</span>
	      <span class="snumb">学籍号：{{ form.studentCode }}</span>
	    </el-form-item>
	    <el-form-item label="学生状态：">
	      <span class="scontent">{{ form._studentStatus }}</span>
	    </el-form-item>
	    <el-form-item label="综素成绩：">
	      <span class="scontent">{{ form.totalIndexScore }}</span>
	    </el-form-item>
	    <el-form-item label="检测成绩：" v-if="form.studentStatus == '0'">
	      <el-table
	        :data="form.conlist"
	        style="width: 100%"
	        border
	        :header-cell-style="tableHeaderColor"
	        :cell-style="cellStyleFun"
	      >
	        <el-table-column type="index" label="序号" width="50">
	        </el-table-column>
	        <el-table-column prop="project" label="检测项"> </el-table-column>
	        <el-table-column prop="type" label="测评结果" width="120"></el-table-column>
	        <el-table-column
	          prop="typeGradesScore"
	          label="体质检测成绩"
	          width="120"
	        ></el-table-column>
	        <el-table-column prop="typeIndexScore" label="综素得分" width="120">
	        </el-table-column>
	        <el-table-column prop="typeLevel" label="等级" width="120">
	        </el-table-column>
	      </el-table>
	    </el-form-item>
	    <el-form-item label="检测成绩：" v-if="form.studentStatus == '1'">
	      <span class="scontent"
	        >根据教育部发布的学生综合素质评价规则，因残疾无法参与体质检测的学生，默认获得70%综素成绩</span
	      >
	    </el-form-item>
	    <el-form-item label="检测成绩：" v-if="form.studentStatus == '2'">
	      <span class="scontent"
	        >根据教育部发布的学生综合素质评价规则，因受伤无法参与体质检测的学生，默认获得60%综素成绩</span
	      >
	    </el-form-item>
	  </el-form>
	  <span slot="footer" class="dialog-footer">
	    <el-button @click="cancel">确定</el-button>
	  </span>
	</el-dialog>
  </div>
</template>

<script>
import Search from "@/components/Search";
import { getStudentStatus } from "@/api/teacher/quality/health";
import {
  getConstitution,
  importConstitution,
  saveConstitution,
} from "@/api/teacher/quality/health";
export default {
  data() {
    return {
      usualUrl: `${process.env.VUE_APP_API}/shida-user-biz/static/体质检测成绩模板.xls`,
      pdfUrl: `${process.env.VUE_APP_API}/shida-user-biz/static/国家学生体质健康标准.pdf`,
      tableData: [],
      total: 0,
      queryParams: {
        pageSize: 100,
      },
      typeId: "320",
      open: false,
      openImport: false,
      openPdf: false,
	  openDetail: false,
      termlist: [],
      classlist: [],
      form: {
        fileList: [],
        conlist: [],
      },
      stuStatus: [],
      submitForm: {},
      fields: {
        学生姓名: "userName",
        学籍号: "loginName",
        班级: "className",
        学生状态: "studentStatus",
        体质检测成绩: "totalGradesScore",
        综素得分: "totalIndexScore",
      },
    };
  },
  components: { Search },
  created() {
    this.getStuStatus();
  },
  methods: {
    getFirst() {
      this.$store.dispatch("GetTermList").then((res) => {
        this.termlist = res.data;
      });
      this.$store.dispatch("GetClassList", this.typeId).then((res) => {
        this.classlist = res.data;
        this.queryParams.term = this.$store.getters.queryForm.termName;
        this.queryParams.deptId = this.$store.getters.queryForm.deptId;
        this.$refs.search.init(this.queryParams.term, this.queryParams.deptId);
        this.getList();
      });
    },
    getList() {
      getConstitution(this.queryParams).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total;
        this.tableData.forEach((item) => {
          switch (item.studentStatus) {
            case "0":
              item.studentStatus = "正常";
              break;
            case "1":
              item.studentStatus = "残疾";
              break;
            case "2":
              item.studentStatus = "受伤";
              break;
          }
        });
      });
    },
    handleQuery(data) {
      this.queryParams.deptId = data.deptId;
      this.queryParams.term = data.term;
      this.queryParams.studentStatus = data.userStatus;
      this.queryParams.userNameOrLoginName = data.userName;
      this.getList();
    },
    handleImport() {
      this.openImport = true;
    },
    submitImport() {
      var formData = new FormData();
      formData.append("file", this.form.file);
      importConstitution(formData).then((res) => {
        this.$message.success("成功导入成绩");
        this.openImport = false;
        this.form.fileList = [];
        this.getList();
      });
    },
    cancel() {
      this.form.fileList = [];
      this.openImport = false;
      this.open = false;
	  this.openDetail = false;
      this.getList();
    },
    handleUpChange(file, fileList) {
      this.form.file = file.raw;
      this.$message.success("导入成功");
    },
    handleRemove(row) {},
    handleExceed() {
      this.$message.error("每次只能上传一个文件！");
    },
    handleEdit(row) {
      this.open = true;
      this.form = JSON.parse(JSON.stringify(row));
      if (this.form.studentStatus === "正常") {
        this.form.studentStatus = "0";
      } else if (this.form.studentStatus === "残疾") {
        this.form.studentStatus = "1";
      } else if (this.form.studentStatus === "受伤") {
        this.form.studentStatus = "2";
      }
      this.form.userName = row.userName;
      this.form.studentCode = row.loginName;
      this.form.id = row.id;
      this.form.conlist = [
        {
          project: "体重",
          type: this.form.studentWeight,
          typeGradesScore: "——",
          typeIndexScore: "——",
          typeLevel: "——",
        },
        {
          project: "身高",
          type: this.form.studentHeight,
          typeGradesScore: "——",
          typeIndexScore: "——",
          typeLevel: "——",
        },
        {
          project: "体重指数(BMI)(kg/m2)",
          type: this.form.type1,
          typeGradesScore: this.form.type1GradesScore,
          typeIndexScore: this.form.type1IndexScore,
          typeLevel: this.form.type1Level,
        },
        {
          project: "肺活量(毫升)",
          type: this.form.type2,
          typeGradesScore: this.form.type2GradesScore,
          typeIndexScore: this.form.type2IndexScore,
          typeLevel: this.form.type2Level,
        },
        {
          project: "50米跑(秒)",
          type: this.form.type3,
          typeGradesScore: this.form.type3GradesScore,
          typeIndexScore: this.form.type3IndexScore,
          typeLevel: this.form.type3Level,
        },
        {
          project: "坐位体前屈(厘米)",
          type: this.form.type4,
          typeGradesScore: this.form.type4GradesScore,
          typeIndexScore: this.form.type4IndexScore,
          typeLevel: this.form.type4Level,
        },
        {
          project: "立定跳远(厘米)",
          type: this.form.type5,
          typeGradesScore: this.form.type5GradesScore,
          typeIndexScore: this.form.type5IndexScore,
          typeLevel: this.form.type5Level,
        },
        {
          project: "引体向上(男)/一分钟仰卧起坐(女)",
          type: this.form.type6,
          typeGradesScore: this.form.type6GradesScore,
          typeIndexScore: this.form.type6IndexScore,
          typeLevel: this.form.type6Level,
        },
        {
          project: "1000米(男)/800(女)(分秒)",
          type: this.form.type7,
          typeGradesScore: this.form.type7GradesScore,
          typeIndexScore: this.form.type7IndexScore,
          typeLevel: this.form.type7Level,
        },
      ];
    },
	// 查看
	handleCheck(row) {
		this.openDetail = true;
		this.form = JSON.parse(JSON.stringify(row));
		this.form._studentStatus = this.form.studentStatus
		if (this.form.studentStatus === "正常") {
		  this.form.studentStatus = "0";
		} else if (this.form.studentStatus === "残疾") {
		  this.form.studentStatus = "1";
		} else if (this.form.studentStatus === "受伤") {
		  this.form.studentStatus = "2";
		}
		this.form.userName = row.userName;
		this.form.studentCode = row.loginName;
		this.form.id = row.id;
		this.form.conlist = [
		  {
		    project: "体重",
		    type: this.form.studentWeight,
		    typeGradesScore: "——",
		    typeIndexScore: "——",
		    typeLevel: "——",
		  },
		  {
		    project: "身高",
		    type: this.form.studentHeight,
		    typeGradesScore: "——",
		    typeIndexScore: "——",
		    typeLevel: "——",
		  },
		  {
		    project: "体重指数(BMI)(kg/m2)",
		    type: this.form.type1,
		    typeGradesScore: this.form.type1GradesScore,
		    typeIndexScore: this.form.type1IndexScore,
		    typeLevel: this.form.type1Level,
		  },
		  {
		    project: "肺活量(毫升)",
		    type: this.form.type2,
		    typeGradesScore: this.form.type2GradesScore,
		    typeIndexScore: this.form.type2IndexScore,
		    typeLevel: this.form.type2Level,
		  },
		  {
		    project: "50米跑(秒)",
		    type: this.form.type3,
		    typeGradesScore: this.form.type3GradesScore,
		    typeIndexScore: this.form.type3IndexScore,
		    typeLevel: this.form.type3Level,
		  },
		  {
		    project: "坐位体前屈(厘米)",
		    type: this.form.type4,
		    typeGradesScore: this.form.type4GradesScore,
		    typeIndexScore: this.form.type4IndexScore,
		    typeLevel: this.form.type4Level,
		  },
		  {
		    project: "立定跳远(厘米)",
		    type: this.form.type5,
		    typeGradesScore: this.form.type5GradesScore,
		    typeIndexScore: this.form.type5IndexScore,
		    typeLevel: this.form.type5Level,
		  },
		  {
		    project: "引体向上(男)/一分钟仰卧起坐(女)",
		    type: this.form.type6,
		    typeGradesScore: this.form.type6GradesScore,
		    typeIndexScore: this.form.type6IndexScore,
		    typeLevel: this.form.type6Level,
		  },
		  {
		    project: "1000米(男)/800(女)(分秒)",
		    type: this.form.type7,
		    typeGradesScore: this.form.type7GradesScore,
		    typeIndexScore: this.form.type7IndexScore,
		    typeLevel: this.form.type7Level,
		  },
		];
	},
    handleSubmit() {
      var str = "";
      if (this.form.studentStatus == "0") {
        this.submitForm = {
          id: this.form.id,
          studentStatus: this.form.studentStatus,
          studentWeight: this.form.conlist[0].type,
          studentHeight: this.form.conlist[1].type,
          type2: this.form.conlist[3].type,
          type3: this.form.conlist[4].type,
          type4: this.form.conlist[5].type,
          type5: this.form.conlist[6].type,
          type6: this.form.conlist[7].type,
          type7: this.form.conlist[8].type,
        };
      } else {
        this.submitForm = {
          id: this.form.id,
          studentStatus: this.form.studentStatus,
          studentWeight: 0,
          studentHeight: 0,
          type2: 0,
          type3: 0,
          type4: 0,
          type5: 0,
          type6: 0,
          type7: 0,
        };
      }
      this.form.conlist.forEach((item, index) => {
        if (index != 2) {
          if (item.type != "") {
          } else {
            str += item.project + "、";
          }
        }
      });
      str = str.slice(0, str.length - 1);
      if (str.length > 0) {
        this.$message.error("请填写" + str + "项");
      } else {
        saveConstitution(this.submitForm).then((res) => {
          // console.log(res);
          this.$message.success("修改成功");
          this.open = false;
          this.getList();
        });
      }
    },
    handleChange(val) {
      if (val == "0") {
        this.submitForm = {
          id: this.form.id,
          studentStatus: this.form.studentStatus,
          studentWeight: this.form.conlist[0].typeGradesScore,
          studentHeight: this.form.conlist[1].typeGradesScore,
          type2: this.form.conlist[2].typeGradesScore,
          type3: this.form.conlist[3].typeGradesScore,
          type4: this.form.conlist[4].typeGradesScore,
          type5: this.form.conlist[5].typeGradesScore,
          type6: this.form.conlist[6].typeGradesScore,
          type7: this.form.conlist[7].typeGradesScore,
        };
      } else {
        this.submitForm = {
          id: this.form.id,
          studentStatus: this.form.studentStatus,
          studentWeight: 0,
          studentHeight: 0,
          type2: 0,
          type3: 0,
          type4: 0,
          type5: 0,
          type6: 0,
          type7: 0,
        };
      }
    },
    getStuStatus() {
      getStudentStatus({ type: "userStatus" }).then((res) => {
        // console.log(res,'status');
        this.stuStatus = res.data;
      });
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color: #F6F7FA;color: #616266;font-weight: blod;text-align:center";
      }
    },
    cellStyleFun() {
      return "text-align:center";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/el-tabs.scss";
@import "@/assets/scss/studentResult.scss";
</style>
<style scoped>
.el-form-item {
  margin-bottom: 12px;
}
.el-form-item >>> .el-form-item__label {
  font-weight: bold;
  color: #303133;
}
.el-image {
  width: 100%;
}
</style>